import './Floater.scss';

const Floater = ({ value, link }) => {
  const show =
    value > 380 && value < 4000
      ? { transform: 'none', transitionDuration: '300ms' }
      : { transform: 'translateY(140%)', transitionDuration: '300ms' };
  return (
    <div className='floater_container' style={show}>
      <a
        href={link}
        style={{
          textDecoration: 'none',
          width: '90%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button id="floater_link" style={{ margin: '24px 0 32px' }}>Download Now</button>
      </a>
    </div>
  );
};

export default Floater;
