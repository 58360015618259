import { useState } from 'react';

import styles from './Install.module.scss';

import { dkInstallArr, dkSteps } from '../../../utils/helpers';
import { Arrow } from '../../../utils/svg';

const Install = () => {
  const [step, setStep] = useState({
    current: 0,
  });

  return (
    <section className={styles.install_container}>
      <div className={styles.install_inner}>
        <div className={styles.install_header}>
          <h1>How to Install</h1>
        </div>
        <div className={styles.install_content}>
          <div className={styles.install_details}>
            {dkSteps.map((item, idx) => (
              <img
                key={`img-${idx}`}
                src={item}
                alt='step'
                style={
                  step.current == idx ? { opacity: '1' } : { opacity: '0' }
                }
                loading='lazy'
              />
            ))}
          </div>
          <div className={styles.install_steps}>
            {dkInstallArr.map((item, idx) => (
              <div
                key={`step-${idx}`}
                className={step.current == idx ? styles.active : styles.step}
                id={step.current == idx ? 'active' : ''}
                onClick={() =>
                  setStep((state) => {
                    return { current: idx };
                  })
                }
              >
                {step.current === idx ? (
                  <div className={styles.arrow}>
                    <Arrow />
                  </div>
                ) : null}
                <div className={styles.step_img}>
                  <img src={item.image} alt='download' loading='lazy' />
                </div>
                <div className={styles.step_details}>
                  <div className={styles.step_heading}>
                    <h1>{item.heading}</h1>
                  </div>
                  <div className={styles.step_info}>{item.details}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Install;
