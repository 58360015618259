import React from 'react';
import { footerItems } from '../../../utils/helpers';
import styles from './Footer.module.scss';

const Footer = React.forwardRef((props, ref) => {

  const scrollToForm = () => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  return (
    <div className={styles.footer_container}>
      <div className={styles.footer_inner}>
        <div className={styles.footer_content}>
          <div className={styles.footer_logo}>
            <img
              src='https://static.frizza.in/frizza/offer/icon/f94f8b59-aa36-4bad-8818-871ffdfae603.png'
              alt='Frizza'
              loading='lazy'
            />
          </div>
          <ul className={styles.footer_items}>
            {footerItems.map((item, idx) => (
              <li key={`ft-${idx}`}>
                {idx === 0 ? (
                  <span style={{ cursor: 'pointer' }} onClick={scrollToForm}>
                    {item.name}
                  </span>
                ) : (
                  <a
                    href={item.link}
                    target='_blank'
                    style={{ textDecoration: 'none', color: '#d9dbe1' }}
                  >
                    {item.name}
                  </a>
                )}
              </li>
            ))}
          </ul>
          <div className={styles.footer_copyrights}>
            <span>© 2022 Frizza. All rights reserved</span>
          </div>
        </div>
        <div className={styles.link_group}>
          <div className={styles.footer_links}>
            <span>Follow us on:</span>
            <div className={styles.links}>
              <a
                href='https://www.facebook.com/frizza.recharge/'
                target='_blank'
              >
                <img
                  src='https://static.frizza.in/frizza/offer/icon/a8fdc3f9-afc1-4786-9006-e42b3d582a66.png'
                  alt='Facebook'
                  loading='lazy'
                />
              </a>
              <a href='https://twitter.com/frizza12' target='_blank'>
                <img
                  src='https://static.frizza.in/frizza/offer/banner/e8976a26-8251-45b3-ba4a-a3fa48fb0ee7.png'
                  alt='Twitter'
                  loading='lazy'
                />
              </a>
              <a href='https://www.instagram.com/frizza_india/' target='_blank'>
                <img
                  src='https://static.frizza.in/frizza/offer/banner/b612119c-38da-4aa4-a26f-ca883f1c68df.png'
                  alt='Instagram'
                  loading='lazy'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Footer;
