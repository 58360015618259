import Header from './sections/Header';

import Banner from './sections/Banner';
import Floater from './common/Floater';
import Reviews from './sections/Reviews';
import About from './sections/About';
import Install from './sections/Install';
import Withdraw from './sections/Withdraw';
import Refer from './sections/Refer';
import Faq from './sections/Faq';

import Footer from './sections/Footer';
import Modal from './common/Modal';

import { useState, useEffect } from 'react';

const MobilePage = ({ link }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [modal, setModal] = useState(false);
  const [nodes, setNodes] = useState([]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const modalHandler = () => {
    setModal((state) => !state);
  };

  useEffect(() => {
    let elems = Array.from(document.getElementsByTagName('h1'));
    let lastElem = elems[elems.length - 1];
    let nodeElems = elems.slice(0, 3);

    nodeElems.push(lastElem);
    setNodes(nodeElems);

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modal]);

  return (
    <>
      <Header modalHandler={modalHandler} />
      <main>
        <Banner link={link} />
        <Reviews />
        <About />
        <Install />
        <Withdraw />
        <Refer />
        <Faq />
      </main>
      <footer>
        <Footer link={link}/>
      </footer>
      <Floater value={scrollPosition} link={link} />
      {modal ? <Modal modalHandler={modalHandler} nodes={nodes} /> : null}
    </>
  );
};

export default MobilePage;
