import logo from '../../../utils/images/frizza_logo.png';

import styles from './Modal.module.scss';
import { navItems } from '../../../utils/helpers';
import { Close } from '../../../utils/svg';

const Modal = ({ modalHandler, nodes }) => {
  const scrollHandler = (idx) => {
    nodes[idx].scrollIntoView();
    modalHandler();
  };

  return (
    <div className={styles.modal_container}>
      <div className={styles.modal_inner}>
        <div className={styles.modal_header}>
          <div className={styles.logo}>
            <img src={logo} alt='Frizza' />
          </div>
          <div className={styles.close} onClick={() => modalHandler()}>
            <Close />
          </div>
        </div>

        <ul className={styles.modal_items}>
          {navItems.map((item, idx) => (
            <li key={`modal-${idx}`} onClick={() => scrollHandler(idx)}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Modal;
