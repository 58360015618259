import styles from './Banner.module.scss';

import main from '../../../utils/images/main.png';

const Banner = ({ link }) => {
  return (
    <div className={styles.hero_container}>
      <img className={styles.main_bg} src={main} alt='main' />
      <div className={styles.hero_inner}>
        <span className={styles.header}>Download Frizza!</span>
        <span className={styles.sub_header}>Start Your Earnings Journey</span>
        <a
          style={{
            margin: '24px 0 32px',
            textDecoration: 'none',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
          href={link}
        >
          <button id="mob_download_link">Download Now</button>
        </a>
        <div className={styles.metrics}>
          <div className={styles.cashback}>
            <span>10M+</span>
            <span>Downloads</span>
          </div>
          <div className={styles.offers}>
            <span>5L+</span>
            <span>Daily Earnings</span>
          </div>
        </div>
        <div>
          <img
            style={{ marginTop: '42px' }}
            src='https://static.frizza.in/frizza/offer/banner/3c2f5ee7-ec53-4e37-8449-d55d6db874a1.png'
            alt='mobile'
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
