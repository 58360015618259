import axios from 'axios';

const WEBSITE_ROOT_API = process.env.REACT_APP_WEBSITE_ROOT_API;

const config = (options = {}) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const responseBody = (res) => res.data;

export const submit = (payload) =>
  axios
    .post(`${WEBSITE_ROOT_API}/api/v1/apk_download_sms`, payload, config())
    .then(responseBody);

export const fetchDownloadLink = () =>
  axios
    .get(`${WEBSITE_ROOT_API}/api/website_metadata`, config())
    .then(responseBody);
