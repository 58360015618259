import './Card.scss';

const Card = ({ data }) => {
  return (
    <div className='dk_slider_card'>
      <div className='slider_card_inner'>
        <div className='name_container'>
          <img
            src={data.image}
            style={{ borderRadius: '50%' }}
            width='41'
            height='41'
            alt=''
            loading='lazy'
          />
          <div className='name_inner'>
            <span>{data.name}</span>
            <img src="https://static.frizza.in/frizza/offer/icon/247df750-ce4e-498a-a309-cd3ab04ee8bd.png" alt='stars' loading='lazy' />
          </div>
        </div>
        <div className='title'>Review</div>
        <div className='comment'>{data.comment}</div>
      </div>
    </div>
  );
};

export default Card;
