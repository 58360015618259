import Slider from 'react-slick';

import styles from './About.module.scss';

const settings = {
  className: 'center',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 1,
  swipeToSlide: true,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  cssEase: 'linear',
  pauseOnHover: false,
};

const settings_2 = {
  className: 'center',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 3,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'linear',
  pauseOnHover: false,
};

const About = () => {
  return (
    <section className={styles.about_container}>
      <div className={styles.about_inner}>
        <div className={styles.about_heading}>
          <h1>About Frizza</h1>
        </div>
        <div className={styles.about_details}>
          Frizza offers you the chance to earn money via Paytm and UPI with
          offerings like Daily App Offers, Reading News and Articles, Watching
          Videos, Daily Check-in, Referrals and much more
        </div>
        <div className='about_slider'>
          <Slider {...settings_2}>
            <div className='first_header'>
              <span>Complete a task</span>
            </div>
            <div className='second_header'>
              <span>Earn wallet cash</span>
            </div>
            <div className='third_header'>
              <span>Transfer to your account</span>
            </div>
          </Slider>
        </div>
        <div className='about_slider_pics'>
          <Slider {...settings}>
            <div>
              <img
                src='https://static.frizza.in/frizza/offer/icon/6237ca8f-31d9-46a8-94c1-40ac96733399.png'
                alt='Task'
                loading='lazy'
                width='706.11px'
                height='506.11px'
              />
            </div>
            <div>
              <img
                src='https://static.frizza.in/frizza/offer/icon/dae0e9fb-ca83-460d-9390-f7e8b43556b0.png'
                alt='Earn wallet cash'
                loading='lazy'
                width='706.11px'
                height='506.11px'
              />
            </div>
            <div>
              <img
                src='https://static.frizza.in/frizza/offer/icon/eb76debc-4866-458c-9101-07743ab21df8.png'
                alt='Transfer to your account'
                loading='lazy'
                width='706.11px'
                height='506.11px'
              />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default About;
