import { Accordion } from 'react-bootstrap';

import styles from './Faq.module.scss';

import { DKLogo } from '../../../utils/svg';

const Faq = () => {
  return (
    <section className={styles.faq_container}>
      <div className={styles.faq_inner}>
        <div className={styles.logo}>
          <DKLogo />
        </div>

        <div className={styles.header}>
          <h1>Frequently Asked Questions</h1>
        </div>

        <div>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>How does Frizza Work?</Accordion.Header>
              <Accordion.Body>
                Complete simple tasks, read articles, fill in surveys and play
                lots of free games to accumulate wallet reward money and
                transfer the money immediately to your paytm wallet or UPI.
                {'\n'}
                Once you reach the minimum wallet money threshold, you can
                exchange it for real PayTM cash or UPI transfer.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>How to Earn Money on Frizza?</Accordion.Header>
              <Accordion.Body>
                Frizza offers cash rewards on trying different offers on the
                application.{'\n'} You also get cash just for checking in daily
                on the application.{'\n'} Once you make a money transfer,
                'Frizza Articles' get unlocked where you can start earning daily
                for reading the latest news.{'\n'} Once you try all available
                offers, 'Frizza Video' gets unlocked where you can start earning
                daily for watching trending videos.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>
                Do I get real money on Frizza?
              </Accordion.Header>
              <Accordion.Body>
                Yes, Absolutely. NO Vouchers or Gift Cards – You are paid
                instant cash rewards in your wallet!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='3'>
              <Accordion.Header>
                How can I transfer my wallet amount?
              </Accordion.Header>
              <Accordion.Body>
                Once you unlock your wallet amount, you can either transfer the
                money to your PayTM Wallet or any UPI account of your choice
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='4'>
              <Accordion.Header>
                When does Frizza transfer money?
              </Accordion.Header>
              <Accordion.Body>
                Frizza transfers the money realtime and 90% of our transactions
                are processed within 10s.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='5'>
              <Accordion.Header>
                How to Earn from Referring Frizza?
              </Accordion.Header>
              <Accordion.Body>
                Every user has a unique referral code. You can share the
                referral code with your friend and once they complete the
                referral conditions, your wallet gets credited.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
