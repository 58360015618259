import React from 'react';
import styles from './Refer.module.scss';

const Refer = React.forwardRef((props, ref) => {

  const scrollToForm = () => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };

  return (
    <section className={styles.refer_container}>
      <div className={styles.refer_inner}>
        <div className={styles.refer_content}>
          <div className={styles.heading}>
            <h1>Refer your friend & earn lifetime rewards.</h1>
          </div>

          <div className={styles.details}>
            Earn wallet cash when your friend completes the referral conditions.
            Earn a percentage of their offer earnings once you recieve the
            referral reward
          </div>

          <div className={styles.link}>
            <span>Get the app:</span>
            <div onClick={scrollToForm}>
              <button id='dk_download_link'>Download Frizza Now</button>
            </div>
            <span>OR</span>
            <a
              href='https://play.google.com/store/apps/details?id=com.rocketcharge'
              target='_blank'
            >
              <img
                src='https://static.frizza.in/frizza/offer/icon/ee33b1bd-d839-4601-b13f-54d74b5127b7.png'
                alt='Google Play'
                loading='lazy'
                style={{ marginBottom: '1px' }}
              />
            </a>
          </div>
        </div>

        <div className={styles.images}>
          <img
            src='https://static.frizza.in/frizza/offer/icon/702fbb3a-3e7a-481b-983a-b7a5b919baf0.png'
            alt='phone1'
            loading='lazy'
          />
          <img
            src='https://static.frizza.in/frizza/offer/icon/8fe92389-4b31-4785-a751-e8c63ef1819f.png'
            alt='phone2'
            loading='lazy'
          />
        </div>
      </div>
    </section>
  );
});

export default Refer;
