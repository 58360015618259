import styles from './Header.module.scss';

import logo from '../../../utils/images/frizza_logo.png';
import { navItems } from '../../../utils/helpers';

const Header = ({ nodes }) => {

  const scrollHandler = (idx) => {
    nodes[idx].scrollIntoView();
  };

  return (
    <header className={styles.header}>
      <nav id='mob-nav'>
        <div className="logo">
          <img src={logo} alt='Frizza Logo' width='90' height='34' />
          <div className="branding">
            <span  >Powered By</span>
            {' '}
            <a className="brand-logo" href="https://collegedunia.com/about">
              <img height="30" src="https://images.static-collegedunia.com/public/asset/img/cd_logo.png" id="branding-powered-by-logo"  />
            </a>
          </div>
        </div>
        <ul className={styles.items}>
          {navItems.map((item, idx) => (
            <li key={`nav-${idx}`} onClick={() => scrollHandler(idx)}>
              {item}
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
