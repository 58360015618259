import { useState, useEffect } from 'react';
import MobilePage from './components/mobile';
import { fetchDownloadLink } from './agent';
import DesktopPage from './components/desktop';

import { useIsDesktop } from './utils/helpers/responsive';

function App() {
  const [link, setLink] = useState();

  useEffect(() => {
    fetchDownloadLink()
      .then((res) => setLink(res.url))
      .catch((err) => console.log('LINK_FETCH_FAILED'));
  }, []);

  const isDesktop = useIsDesktop();

  return (
    <>{isDesktop ? <DesktopPage link={link} /> : <MobilePage link={link} />}</>
  );
}

export default App;
