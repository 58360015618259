import { useState } from 'react';

import styles from './Install.module.scss';

import { installStepsArr, steps } from '../../../utils/helpers';

const Install = () => {
  const [step, setStep] = useState({
    current: 0,
  });

  return (
    <section className={styles.install_container}>
      <div className={styles.install_inner}>
        <div className={styles.install_header}>
          <h1>How to Install</h1>
        </div>
        <div className={styles.install_details}>
          <img
            src={steps.filter((item, idx) => idx == step.current)}
            alt='step'
          />
        </div>
        <div className={styles.install_steps}>
          {installStepsArr.map((item, idx) => (
            <div
              key={`step-${idx}`}
              className={step.current == idx ? styles.active : styles.step}
              id={step.current == idx ? 'active' : ''}
              onClick={() =>
                setStep((state) => {
                  return { current: idx };
                })
              }
            >
              {step.current === idx ? (
                <div className={styles.arrow}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='17'
                    viewBox='0 0 21 17'
                    fill='none'
                  >
                    <path
                      d='M11.3508 1.37748L20.0578 15.4745C20.4693 16.1407 19.99 17 19.207 17L1.79301 17C1.00996 17 0.530725 16.1407 0.942215 15.4745L9.6492 1.37748C10.04 0.744806 10.96 0.744804 11.3508 1.37748Z'
                      fill='#35C9EF'
                    />
                  </svg>
                </div>
              ) : null}
              <div className={styles.step_img}>
                <img
                  src={item.image}
                  alt='download'
                  width='51'
                  height='51'
                  loading='lazy'
                />
              </div>
              <div className={styles.step_details}>
                <div className={styles.step_heading}>
                  <h1>{item.heading}</h1>
                </div>
                <div className={styles.step_info}>{item.details}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Install;
