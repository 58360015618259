import styles from './Withdraw.module.scss';

const Withdraw = () => {
  return (
    <section className={styles.withdraw_container}>
      <div className={styles.withdraw_inner}>
        <div className={styles.header}>
          <h1>Withdraw cashback directly to your</h1>
        </div>
        <div className={styles.payment}>
          <div>
            <img
              src='https://static.frizza.in/frizza/offer/icon/24e4352f-9af2-407a-9803-d3f744dab19d.png'
              alt='paytm'
              loading='lazy'
              width='312px'
              height='176px'
            />
            <span>Paytm Wallet</span>
          </div>
          <div>
            <img
              src='https://static.frizza.in/frizza/offer/icon/f3772ace-deb7-4a1a-99b9-cb738f888ef3.png'
              alt='upi'
              loading='lazy'
              width='340px'
              height='176px'
            />
            <span>UPI Account</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Withdraw;
