import styles from './Header.module.scss';

import logo from '../../../utils/images/frizza_logo.png';

const Header = ({ modalHandler }) => {
  return (
    <header className={styles.header}>
      <nav id='mob-nav'>
        <div className="logo">
          <img src={logo} alt='Frizza Logo' width='90' height='34' />
          <div className="branding">
            <span  >Powered By</span>
            {' '}
            <a className="brand-logo" href="https://collegedunia.com/about">
              <img height="30" src="https://images.static-collegedunia.com/public/asset/img/cd_logo.png" id="branding-powered-by-logo"  />
            </a>
          </div>
        </div>
        <div className={styles.expand} onClick={() => modalHandler()}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
          >
            <path
              d='M12 14H28'
              stroke='#111827'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 20H28'
              stroke='#111827'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 26H28'
              stroke='#111827'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </nav>
    </header>
  );
};

export default Header;
