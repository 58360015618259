import Slider from 'react-slick';

import styles from './Reviews.module.scss';

import Card from '../common/Card';

import { imagrArr } from '../../../utils/helpers';

const settings = {
  className: 'center',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 1,
  swipeToSlide: true,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  cssEase: 'linear',
};

const Reviews = () => {
  return (
    <section className={styles.review_container}>
      <h1>Customer Reviews</h1>

      <div className={styles.review_details_card}>
        <div className={styles.review_inner}>
          <div className={styles.rating_details}>
            <span className={styles.ratings}>Ratings</span>
            <span className={styles.ratings_num}>4.0</span>
            <img
              src='https://static.frizza.in/frizza/offer/icon/89e6a191-2e63-40bc-b1f3-2372b8b12607.png'
              alt='stars'
              width='80'
              loading='lazy'
            />
            <p>
              Based on <span>67.1k reviews</span>
            </p>
          </div>
          <div className={styles.rating_bars}>
            <img
              src='https://static.frizza.in/frizza/offer/banner/946cc6d0-9cae-41e7-a47b-15cd08bd2bc0.png'
              alt='review bars'
              loading='lazy'
            />
          </div>
        </div>
      </div>

      <Slider {...settings}>
        {imagrArr.map((item, idx) => (
          <Card key={`card-${idx}`} data={item} />
        ))}
      </Slider>
    </section>
  );
};

export default Reviews;
