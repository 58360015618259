import Slider from 'react-slick';

import styles from './About.module.scss';
import '../../../utils/sass/common.scss';

const settings = {
  className: 'center',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 1,
  swipeToSlide: true,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  cssEase: 'linear',
};

const settings_2 = {
  className: 'center',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 3,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'linear',
};

const About = () => {
  return (
    <section className={styles.about_container}>
      <div className={styles.about_inner}>
        <div className={styles.about_heading}>
          <h1>About Frizza</h1>
        </div>
        <div className={styles.about_details}>
          Frizza offers you the chance to earn money via Paytm and UPI with
          offerings like Daily App Offers, Reading News and Articles, Watching
          Videos, Daily Check-in, Referrals and much more
        </div>
        <div className='about_slider'>
          <Slider {...settings_2}>
            <div className='first_header'>
              <span>Complete a task</span>
            </div>
            <div className='second_header'>
              <span>Earn wallet cash</span>
            </div>
            <div className='third_header'>
              <span>Account Transfer</span>
            </div>
          </Slider>
        </div>
        <div className='about_slider_pics'>
          <Slider {...settings}>
            <div>
              <img
                src='https://static.frizza.in/frizza/offer/icon/7b53213c-72e4-41bf-8ad4-3c69a13feaa4.png'
                alt='Complete a task'
                loading='lazy'
                width='329px'
                height='250px'
              />
            </div>
            <div>
              <img
                src='https://static.frizza.in/frizza/offer/icon/e1c46683-5705-4524-adcf-e53ed18d785e.png'
                alt='Earn wallet cash'
                loading='lazy'
                width='329px'
                height='250px'
              />
            </div>
            <div>
              <img
                src='https://static.frizza.in/frizza/offer/icon/6bcf673d-8974-40a7-908a-f6c1c7442e9a.png'
                alt='Transfer to account'
                loading='lazy'
                width='329px'
                height='250px'
              />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default About;
