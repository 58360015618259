import styles from './Withdraw.module.scss';

const Withdraw = () => {
  return (
    <section className={styles.withdraw_container}>
      <div className={styles.header}>
        <h1>Withdraw cashback directly to your</h1>
      </div>
      <div className={styles.payment}>
        <div>
          <img
            src='https://static.frizza.in/frizza/offer/icon/c99bfb9c-45ad-4184-91b3-4f0479035859.png'
            alt='paytm'
            width='175'
            loading='lazy'
          />
          <span>Paytm Wallet</span>
        </div>
        <div>
          <img
            src='https://static.frizza.in/frizza/offer/icon/7adaa7fb-d35d-45cc-a273-263731680f8f.png'
            alt='upi'
            width='175'
            loading='lazy'
          />
          <span>UPI Account</span>
        </div>
      </div>
    </section>
  );
};

export default Withdraw;
