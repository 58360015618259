import { useState, useEffect, useRef } from 'react';

import Header from './sections/Header';
import Banner from './sections/Banner';
import Reviews from './sections/Reviews';
import About from './sections/About';
import Install from './sections/Install';
import Withdraw from './sections/Withdraw';
import Refer from './sections/Refer';
import Faq from './sections/Faq';

import Footer from './sections/Footer';

const DesktopPage = ({ link }) => {
  const [nodes, setNodes] = useState([]);

  const formRef = useRef();

  useEffect(() => {
    let elems = Array.from(document.getElementsByTagName('h1'));
    let lastElem = elems[elems.length - 1];
    let nodeElems = elems.slice(0, 3);

    nodeElems.push(lastElem);
    setNodes(nodeElems);
  }, []);

  return (
    <>
      <Header nodes={nodes} />
      <main>
        <Banner ref={formRef} />
        <Reviews />
        <About />
        <Install />
        <Withdraw />
        <Refer ref={formRef} />
        <Faq />
      </main>
      <footer>
        <Footer ref={formRef} />
      </footer>
    </>
  );
};

export default DesktopPage;
