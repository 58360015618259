import React from 'react';
import styles from './Banner.module.scss';

import dkbg from '../../../utils/images/Desktop.png';

import { submit } from '../../../agent';
import { useState } from 'react';

const Banner = React.forwardRef((props, ref) => {
  const [error, setError] = useState({ value: 'NOT_SET' });

  const formSubmitHandler = (event) => {
    event.preventDefault();

    let pattern = /^[6-9]{1}[0-9]{9}$/;

    if (pattern.test(event.target.mobile.value)) {
      setError({ value: 'FALSE' });
      const payload = JSON.stringify({
        mobile: event.target.mobile.value,
      });
      submit(payload).catch((err) => {
        setError({ value: 'TRUE' });
      });
    } else {
      setError({ value: 'TRUE' });
    }
  };

  const clearMessage = () => {
    if (error.value !== 'NOT_SET') {
      setError({ value: 'NOT_SET' });
    }
  };
  return (
    <section className={styles.banner_container}>
      <img src={dkbg} alt='' />
      <div className={styles.banner_inner}>
        <div className={styles.details}>
          <span className={styles.heading}>Download Frizza!</span>
          <span className={styles.sub_heading}>
            Start Your Earnings Journey
          </span>
          <span className={styles.description}>
            Enter your mobile number and start earning on Offers, News, Videos,
            Daily Check-in, Referrals and much more.
          </span>
          <form onSubmit={formSubmitHandler}>
            <input
              type='tel'
              maxLength='10'
              placeholder='Enter mobile number'
              name='mobile'
              onChange={clearMessage}
              ref={ref}
            />
            <button type='submit' id='sms_link'>
              Get SMS with Download Link
            </button>
          </form>
          {error.value === 'TRUE' ? (
            <span
              style={{
                paddingLeft: '4px',
                marginTop: '4px',
                color: 'red',
                fontSize: '14px',
              }}
            >
              Not Valid Phone No.
            </span>
          ) : error.value === 'FALSE' ? (
            <span
              style={{
                paddingLeft: '4px',
                marginTop: '4px',
                color: 'green',
                fontSize: '14px',
              }}
            >
              Message sent successfully!
            </span>
          ) : null}
          <div className={styles.metrics}>
            <div className={styles.cashback}>
              <span>10M+</span>
              <span>Downloads</span>
            </div>
            <div className={styles.offers}>
              <span>5L+</span>
              <span>Daily Earnings</span>
            </div>
          </div>
        </div>
        <div className={styles.mobile}>
          <img
            src='https://static.frizza.in/frizza/offer/banner/259dfa25-23e3-4c50-b0b6-b6b2e1960511.png'
            alt='Mobile'
            loading='lazy'
          />
        </div>
      </div>
    </section>
  );
});

export default Banner;
