export const imagrArr = [
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/ab606d61-4b77-4a69-b91c-221d302380b2.png',
    name: 'Ganesh Kumar',
    comment:
      'I am happy to join this app because it is true and more helpful for earning by perform simple tasks. Download app and watching video for about 1 to 5 minutes. Ai have earned about ₹300 and more.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/6d29bf2f-ab90-478b-af5e-a6b0c08eeca3.png',
    name: 'Nasima Parveen',
    comment:
      'I love this application, its sooo helpful for everyone. It shows us the news and giving money also with it. Its so helpful and great appreciation.👌👌😊😊❤️',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/1cec8f18-e027-49a2-8106-11f40e9670c0.png',
    name: 'Ranjan Bhoy',
    comment:
      'I love this app I have earned ₹80 in this app and i want to earn more',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/c8e7340f-7286-4117-9eb3-18f9894819a4.png',
    name: 'Nemeses God',
    comment: `World's best earning app I love it Its gives real money and instant withdrawal.`,
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/afe4ae02-820b-4380-8aa5-e934afb20a80.png',
    name: 'Philip Sunuwar',
    comment: `The only genuine app in the market I love it absolutely. And it's much better it gives instant withdrawal have been using this from 2020 and it's getting better and better.`,
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/c7dd6dcf-81a3-4312-b0c1-d9b4afb0be9a.png',
    name: 'WHITE TIGERS GAMING',
    comment: 'I earned rs 200 from this love this app.',
  },
];

export const dkInstallArr = [
  {
    image:
      'https://static.frizza.in/frizza/offer/icon/6a072d58-6cf3-4c8b-92a6-958625af1653.png',
    heading: 'START DOWNLOAD',
    details: 'Click ‘Download Anyway’ to download the app.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/icon/fefb3a91-7dab-4153-9b1f-153315e4cfef.png',
    heading: 'OPEN FILE',
    details: 'Click on settings when prompt is shown.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/icon/0230e341-5f4d-4f7a-b124-3a666d72172f.png',
    heading: 'ALLOW UNKNOWN SOURCES',
    details:
      'Go to settings and allow app install from sources other than play store.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/icon/ebc19c10-91f8-4776-ba7d-110062a5fa64.png',
    heading: 'INSTALL AND EARN',
    details: 'Install the Frizza app, open it on your phone and start earning.',
  },
];

export const installStepsArr = [
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/8ea48833-de94-49cc-b9d1-f1857d074430.png',
    heading: 'START DOWNLOAD',
    details: 'Click ‘Download Anyway’ to download the app.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/3159e9a0-af7e-451a-b6a7-aa24fa58b166.png',
    heading: 'OPEN FILE',
    details: 'Click on settings when prompt is shown.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/banner/fbe66999-fb30-49d3-852c-a74da0618dc3.png',
    heading: 'ALLOW UNKNOWN SOURCES',
    details:
      'Go to settings and allow app install from sources other than play store.',
  },
  {
    image:
      'https://static.frizza.in/frizza/offer/icon/fe82fcc7-068b-4e54-a5dd-4ef15a5faf8f.png',
    heading: 'INSTALL AND EARN',
    details: 'Install the Frizza app, open it on your phone and start earning.',
  },
];

export const steps = [
  'https://static.frizza.in/frizza/offer/banner/e15f16e0-3854-4b45-84b5-5bf9d7ce3754.png',
  'https://static.frizza.in/frizza/offer/banner/9c0fa787-c805-4cd7-81d3-654e097ff058.png',
  'https://static.frizza.in/frizza/offer/banner/396c2a07-9fa2-4488-9414-19ded0b2043e.png',
  'https://static.frizza.in/frizza/offer/banner/2ade5e77-b1cd-4176-a3e2-e835cd2cd43a.png',
];

export const dkSteps = [
  'https://static.frizza.in/frizza/offer/icon/f8ee1bc3-db78-4939-bef6-b0feb40b1198.png',
  'https://static.frizza.in/frizza/offer/icon/9ddfa134-5a15-40ec-8fb6-c449dc43aa06.png',
  'https://static.frizza.in/frizza/offer/icon/af579af1-1813-4cf3-be1e-87634e5ac254.png',
  'https://static.frizza.in/frizza/offer/icon/8360de0c-a3a7-435a-9d0f-29ea284facd4.png',
];

export const navItems = [
  'Testimonials',
  'About Frizza',
  'How to install',
  'FAQs',
];

export const footerItems = [
  { name: 'Download Now', link: '' },
  { name: 'Terms & conditions', link: 'https://frizza.in/tnc.html' },
  { name: 'EULA', link: 'https://frizza.in/eula.html' },
  {
    name: 'Privacy Policy',
    link: 'https://frizza.in/privacy.html',
  },
];
