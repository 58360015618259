import styles from './Refer.module.scss';

const Refer = () => {
  return (
    <section className={styles.refer_container}>
      <div className={styles.refer_inner}>
        <div className={styles.heading}>
          <h1>Refer your friend & earn lifetime rewards.</h1>
        </div>

        <div className={styles.details}>
          Earn wallet cash when your friend completes the referral conditions.
          Earn a percentage of their offer earnings once you recieve the
          referral reward
        </div>

        <div className={styles.link}>
          <span>Get the app:</span>
          <a
            href='https://play.google.com/store/apps/details?id=com.rocketcharge'
            target='_blank'
          >
            <img
              src='https://static.frizza.in/frizza/offer/banner/c56016a1-dd79-4529-bf4d-84dc31b60bc1.png'
              alt='Google Play'
              loading='lazy'
            />
          </a>
        </div>

        <div className={styles.images}>
          <img
            src='https://static.frizza.in/frizza/offer/banner/1d91ceaa-f10d-4b41-8a67-97b2234b9a0c.png'
            alt='phone1'
            width='175'
            loading='lazy'
          />
          <img
            src='https://static.frizza.in/frizza/offer/banner/4aa26c5b-88ca-4ecf-8f1b-199592f5a92a.png'
            alt='phone2'
            width='175'
            loading='lazy'
          />
        </div>
      </div>
    </section>
  );
};

export default Refer;
